<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio lead</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="lead_container">
                    <div class="customer">
                        {{ lead.nominative }}
                    </div>
                    <div v-if="lead.city" class="city">
                        {{ lead.city }}
                    </div>
                    <div v-if="lead.value && lead.value > 0" class="valore">
                        <span class="badge_value"> € {{ lead.value }} </span>
                    </div>

                    <div class="contact_container">
                        <div>
                            <span v-if="lead.email" class="btn_contact">
                                <a :href="`mailto:${lead.email}`" class="mobile_link" target="_blank">{{ setShortEmail(lead.email) }}</a>
                            </span>
                        </div>
                        <div>
                            <span v-if="lead.mobile" class="btn_contact">
                                <a :href="`tel:${lead.mobile}`" class="mobile_link" target="_blank">{{ lead.mobile }}</a>
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <div class="title">Titolo</div>
                        <div class="value">
                            {{ lead.title }}
                        </div>
                    </div>
                    <!-- <div class="field">
                        <div class="title">Email</div>
                        <div class="value">
                            <span v-if="lead.email">
                                <a :href="`mailto:${lead.email}`" class="mobile_link">{{ setShortEmail(lead.email) }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Cellulare</div>
                        <div class="value">
                            <span v-if="lead.mobile">
                                <a :href="`tel:${lead.mobile}`" class="mobile_link">{{ lead.mobile }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div> -->
                    <div class="field">
                        <div class="title">Telefono</div>
                        <div class="value">
                            <span v-if="lead.phone">
                                <a :href="`tel:${lead.phone}`" class="mobile_link">{{ lead.phone }}</a>
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="field" @click="toggleShowCalendar()">
                        <div class="title">Richiamare il</div>
                        <div class="value">
                            <span v-if="lead.recall_date" class="badge" :class="isExpired(lead)">
                                {{ dateFormat(lead.recall_date) }}
                            </span>
                            <span v-else class="no_recall_date">Imposta data</span>
                        </div>
                    </div>
                    <div v-if="isCalendarVisible" class="field calendar_container">
                        <!-- <Calendar :value="date" is-expanded :attributes="setAttributes" @dayclick="dayClicked" class="calendar_container"> </Calendar> -->
                        <DatePicker v-model="date" is-expanded />
                        <div @click="setRecallDate()" class="btn_recall_date">Richiama il {{ dateFormat(date) }}</div>
                    </div>
                    <div class="field">
                        <div class="title">Descrizione</div>
                        <div class="value">
                            {{ lead.description ? lead.description : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Probabilità chiusura</div>
                        <div class="value">
                            <span v-if="lead.rating && lead.rating > 0">
                                <span v-for="(i, index) in lead.rating" :key="index">
                                    &#9733;
                                </span>
                            </span>
                            <span v-else> - </span>
                        </div>
                    </div>
                </div>

                <!-- <div class="edit_lead_container">
                    <button @click="openEditLead(lead)">Modifica lead</button>
                </div> -->

                <div class="messages_container">
                    <div class="title">
                        Note / Feedback
                    </div>
                    <div v-if="loading" class="loading_spinner">
                        <ion-spinner name="crescent" color="dark"></ion-spinner>
                    </div>
                    <div v-else>
                        <div v-if="leadMessages.length != 0" class="messages">
                            <div
                                v-for="message in leadMessages"
                                :key="message.lead_messages_id"
                                class="single_message"
                                :class="isWrittenByMe(message.leads_messages_user_id)"
                            >
                                <div class="text">{{ message.leads_messages_text }}</div>
                                <div class="date">{{ dateFormatWithMinutes(message.leads_messages_creation_date) }}</div>
                            </div>
                        </div>
                        <div v-else class="no_messages">
                            Niente da visualizzare
                        </div>
                        <div class="input_container" :style="{ marginBottom: textareaMargin }">
                            <ion-textarea
                                type="text"
                                rows="2"
                                v-model="inputMessage"
                                placeholder="Inserisci nota/feedback..."
                                class="custom_input"
                                :autoGrow="true"
                                @ionFocus="handleFocus"
                                @ionBlur="handleBlur"
                            >
                            </ion-textarea>
                            <button @click="sendMessage" :disabled="sendingData" class="send_message">
                                <ion-icon :icon="arrowForwardCircle"></ion-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    IonTextarea,
    IonSpinner,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";

import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";
import { DatePicker } from "v-calendar";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiLeads from "@/services/leads";

import ModificaLead from "@/components/lead/ModificaLead.vue";

//import { Keyboard } from "@capacitor/keyboard";

export default defineComponent({
    name: "LeadDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        IonTextarea,
        IonSpinner,
        DatePicker,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params
        const router = useRouter();
        const loggeUserId = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const loading = ref(false);

        const lead = ref([]);
        //console.log(props.data);
        lead.value = { ...props.data };

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /* function scrollToInput(event) {
            alert(event);
            console.log(event);
            event.srcElement.scrollIntoView({
                behavior: "smooth",
            });
        } */

        const textareaMargin = ref("0");
        const handleFocus = () => {
            textareaMargin.value = "400px";
        };
        const handleBlur = () => {
            textareaMargin.value = "24px";
        };

        /* Keyboard.addListener("keyboardDidShow", () => {
            if (document.activeElement) {
                document.activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }); */

        /**
         * ! Show/hide calendar if recall date not set
         */
        const date = ref(new Date());
        const isCalendarVisible = ref(false);
        const disableButton = ref(false);

        function toggleShowCalendar() {
            isCalendarVisible.value = !isCalendarVisible.value;
        }
        function closeCalendar() {
            isCalendarVisible.value = false;
        }

        async function setRecallDate() {
            if (date.value) {
                disableButton.value = true;

                const lead_id = lead.value.id;
                const recallDate = moment(date.value).format("YYYY-MM-DD");

                try {
                    const res = await apiLeads.editRecallDate(lead_id, recallDate);

                    if (res.status === 200 && res.data.status === 0) {
                        lead.value.recall_date = res.data.data[0].leads_recall_date;
                        openToast("Data di richiamo salvata correttamente", "toast_success");
                    } else {
                        openToast("Errore durante la modifica del lead", "toast_danger");
                    }
                } catch (error) {
                    openToast("Errore durante la modifica del lead", "toast_danger");
                } finally {
                    disableButton.value = false;
                }
            }
        }

        /**
         * ! Edit lead modal
         * @param {object} lead to edite
         */
        async function openEditLead(lead) {
            //console.log(lead);
            const modal = await modalController.create({
                component: ModificaLead,
                componentProps: {
                    data: lead,
                },
            });
            return modal.present();
        }

        /**
         * Set corrent rating star
         * @param {Object} lead
         */
        function printRating(lead) {
            if (lead.rating) {
                return Array(lead.rating)
                    .fill("&#9733;")
                    .join("");
            }
            return "-";
        }

        /**
         * ! Set corrent bg class for recall date
         */
        const isExpired = computed(() => {
            return (lead) => {
                let className = "";
                const leadDate = moment(lead.recall_date);
                const todayDate = moment();

                if (leadDate.isBefore(todayDate)) {
                    className = "badge_danger";
                }
                return className;
            };
        });

        /**
         * ! Limit lead email to 35 charactes
         */
        function setShortEmail(email) {
            if (!email) {
                return "-";
            } else {
                const trimmedString = email.length > 30 ? email.substring(0, 27) + "..." : email;
                return trimmedString;
            }
        }

        /**
         * ! Get lead messages
         */
        const leadMessages = ref([]);

        async function getLeadMessages() {
            loading.value = true;
            try {
                const res = await apiLeads.loadMessages(lead.value.id);
                leadMessages.value = res.data;
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei messaggi", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * ! Set classes based on who written the message
         */
        const isWrittenByMe = computed(() => {
            return (userId) => {
                let className = "";

                if (userId === loggeUserId) {
                    className = "my_message";
                }
                return className;
            };
        });

        /***************************************
         *
         * ! Send message functionality
         *
         ****************************************/

        const inputMessage = ref("");
        const sendingData = ref(false);

        async function sendMessage() {
            if (inputMessage.value) {
                sendingData.value = true;

                const data = new FormData();
                data.append("leads_messages_customer_id", lead.value.id); //lead id
                data.append("leads_messages_user_id", lead.value.created_by); //logged user id
                data.append("leads_messages_text", inputMessage.value);

                try {
                    const response = await apiLeads.createMessage(data);
                    if (response.status === 0) {
                        const createdMessage = response.data[0];
                        openToast("Messaggio inviato con successo", "toast_success");
                        //inserimento in array messaggi
                        leadMessages.value.push(createdMessage);
                        inputMessage.value = "";
                    } else {
                        openToast("Si è verificato un errore durante l'invio del messaggio", "toast_danger");
                    }
                } catch (error) {
                    openToast("Si è verificato un errore durante l'invio del messaggio", "toast_danger");
                } finally {
                    sendingData.value = false;
                }
            }
        }

        onMounted(() => {
            getLeadMessages();
        });

        return {
            loading,
            isExpired,
            dateFormat,
            dateFormatWithMinutes,
            lead,
            arrowBackOutline,
            closeModal,
            getLeadMessages,
            leadMessages,
            isWrittenByMe,
            inputMessage,
            arrowForwardCircle,
            sendMessage,
            setShortEmail,
            printRating,
            //Calendar
            isCalendarVisible,
            toggleShowCalendar,
            date,
            setRecallDate,
            openEditLead,
            handleFocus,
            handleBlur,
            textareaMargin,
            sendingData,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

/* lead v2*/
.lead_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.lead_container .customer {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.lead_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.lead_container .valore {
    margin-bottom: 16px;
}
.lead_container .valore .badge_value {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.lead_container .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.lead_container .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: none;
}
.lead_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}

/** lead standard */
.lead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.lead .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.lead .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: none;
}
.lead .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    /*color: #ffffff;*/
    color: #000000;
    padding: 4px 8px;
    border-radius: 4px;
}

.value .badge_success {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.value .badge_warning {
    background-color: #f97316;
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.value .badge_danger {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}
ion-button {
    --color: #ffffff;
}

/* Messages */
.messages_container {
    margin-top: 20px;
}
.messages_container .title {
    font-size: 16px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.messages_container .no_messages {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    padding: 16px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
}
.messages_container .messages {
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: scroll;
}
.messages_container .single_message {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.messages_container .single_message .text {
    font-size: 13px;
    margin-bottom: 4px;
}
.messages_container .single_message .date {
    align-self: end;
    font-size: 10px;
    color: #64748b;
}
.messages_container .single_message.my_message {
    align-self: end;
    background-color: #086fa3;
    color: #ffffff;
}
.messages_container .single_message.my_message .date {
    color: #ffffff;
}

.input_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
.send_message {
    width: 10%;
    display: flex;
    justify-content: flex-end;
}
.send_message ion-icon {
    width: 32px;
    height: 32px;
    color: #086fa3;
}
.send_message:disabled {
    opacity: 0.35;
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}
.no_recall_date {
    color: #086fa3;
}

.lead_container .field.calendar_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.btn_recall_date {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 20px;
    text-align: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.edit_lead_container {
    padding: 12px 0;
}
.edit_lead_container button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.contact_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
}

.btn_contact {
    font-size: 10px;
    font-weight: 600;
    color: #086fa3;
    padding: 4px 8px;
    border-radius: 4px;
}
</style>
